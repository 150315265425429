import revive_payload_client_YTGlmQWWT5 from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/Users/geauser/code/happycorp/happycoin/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/geauser/code/happycorp/happycoin/modules/www/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_r3pgF3B1Uq from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import slideovers_oeUu8xcI5w from "/Users/geauser/code/happycorp/happycoin/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/Users/geauser/code/happycorp/happycoin/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/Users/geauser/code/happycorp/happycoin/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/Users/geauser/code/happycorp/happycoin/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/Users/geauser/code/happycorp/happycoin/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_r3pgF3B1Uq,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  chunk_reload_client_SeG0EjL5Ec
]