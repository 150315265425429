export default defineAppConfig({

  ui: {
    primary: "stone",
    gray: "stone",


    button: {
      rounded: "rounded-[4px]",
      font: "font-medium",
      default: {
        color: "black"
      },
      padding: {
        sm: "py-1.5"
      },
      icon: {
        size: {
          xs: "size-3.5",
          sm: "size-4",
        },
      },
    },

    select: {
      rounded: "rounded-[3px]",
    },

    input: {
      base: 'disabled:shadow-none disabled:drop-shadow-none',
      rounded: 'rounded-[3px]',
      variant: {
        outline: 'bg-white dark:bg-gray-900'
      },
    },

    textarea: {
      rounded: 'rounded-[3px]',
    },

    card: {
      body: {
        padding: "px-4 py-5 sm:p-4",
      },
    },


    notification: {
      title: "font-semibold text-sm text-gray-900 dark:text-gray-200 !mb-0.5",
      description: "text-xs !leading-4 !mb-1.5",
      rounded: "rounded-[4px]",
      padding: 'px-4 py-2',
      progress: {
        base: "h-1"
      }
    },


    formGroup: {
      label: {
        base: "text-gray-500"
      },
      error: 'mt-1 text-red-600 dark:text-red-400',
      default: { size: "xs" }
    }

  }

});
